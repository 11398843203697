import {
    CountryInfo,
    getAdded,
    getCarrier, getConfig,
    getGoodsType, getPayTypes, getShopImage,
    GetShopInfo,
    getShopMatters,
    getTimer,
    getWareHouses
} from "@/api/system";
import {getAddress, getCoupon} from "@/api/member";
import { getLanguage } from '@/lang'
import router from "@/router";
import getPageTitle from "@/utils/get-page-title";

const state = {
    path: undefined,
    internal: false,
    shopID: undefined,
    TenantId: undefined,
    language: getLanguage(),
    payTypes: [],
    history: JSON.parse(localStorage.getItem('history')) || [],
    help: [],
    reading: [],
    rightBox: [],
    logoUrl: "",
    shopeeModal: false,
    shop_name: "",
    defaultLanguage: "",
    taxMode: 0,
    systemConfig: [],
    recordNo: ""
}

const mutations = {
    SET_PATH: (state, path) => {
        state.path = path
    },
    SET_HELP_DATA: (state, data) => {
        state.help = data
    },
    SET_INTERNAL: (state, internal) => {
        state.internal = internal
    },
    SET_SHOP_ID: (state, id) => {
        state.shopID = id
    },
    SET_TENANT_ID: (state, tenantId) => {
        state.TenantId = tenantId
    },
    SET_LANGUAGE: (state, language) => {
        state.language = language
        localStorage.setItem('language', language)
    },
    SET_PPY_TYPE: (state, data) => {
        state.payTypes = data
    },
    SET_HISTORY_QUERY: (state, data) => {
        localStorage.setItem('history',  JSON.stringify(data))
        state.history = data
    },
    SET_READING_RECORD: (state, record) => {
        state.reading = record
    },
    SET_RIGHT_BOX: (state, data) => {
        state.rightBox = data
    },
    SET_LOGO_URL: (state, url) => {
        state.logoUrl = url
    },
    SET_SHOPEE: (state, bool) => {
        state.shopeeModal = bool
    },
    SET_TAX_MODE: (state, mode) => {
        state.taxMode = mode
    },
    SET_SHOP_NAME: (state, name) => {
        state.shop_name = name
        document.title = getPageTitle(router.currentRoute.meta.title);
    },
    SET_DEFAULT_LANGUAGE: (state, lang) => {
        state.defaultLanguage = lang
    },
    SET_SYSTEM_CONFIG: (state, data) => {
        state.systemConfig = data
    },
    SET_RECORD_NO: (state, data) => {
        state.recordNo = data
    }
}

const actions = {
    changePath({ commit }, path) {
        commit('SET_PATH', path)
    },
    setLanguage({ commit }, language) {
        commit('SET_LANGUAGE', language)
    },
    headerRecast({ commit }, internal) {
        commit('SET_INTERNAL', internal)
    },
    addRecord({ commit, state }, newsId) {
        if (state.reading.includes(newsId)) return;
        let record = JSON.parse(JSON.stringify(state.reading))
        record.push(newsId)
        commit('SET_READING_RECORD', record)
    },
    getGoodsType() {
        return new Promise((resolve, reject) => {
            const data = {
                PageIndex: 1,
                PageRows: 99,
                ShopId: state.shopID,
                TenantId: state.TenantId
            }
            getGoodsType(data).then(response => {
                const { Code, Data, Msg } = response
                if (Code === 200) {
                    resolve(Data)
                } else {
                    reject(Msg)
                }
            }).catch(err => {
                reject(err)
            })
        })
    },
    getShopMatters({ state }, type) {
        return new Promise((resolve, reject) => {
            const data = {
                PageIndex: 1,
                PageRows: 20,
                Type: type,
                ShopId: state.shopID,
                TenantId: state.TenantId
            }
            getShopMatters(data).then(response => {
                const { Code, Data, Msg } = response
                if (Code === 200) {
                    resolve(Data)
                } else {
                    reject(Msg)
                }
            }).catch(err => {
                reject(err)
            })
        })
    },
    // eslint-disable-next-line no-unused-vars
    getAddress({ commit }, val) {
        return new Promise((resolve, reject) => {
            const data = {
                Type: val
            }
            getAddress(data).then(response => {
                const { Code, Data, Msg } = response
                if (Code === 200) {
                    resolve(Data)
                } else {
                    reject(Msg)
                }
            }).catch(err => {
                reject(err)
            })
        })
    },
    getWareHouse() {
        return new Promise((resolve, reject) => {
            const data = {
                PageIndex: 1,
                PageRows: 20,
                ShopId: state.shopID,
                TenantId: state.TenantId
            }
            getWareHouses(data).then(response => {
                const { Code, Data, Msg } = response
                if (Code === 200) {
                    resolve(Data)
                } else {
                    reject(Msg)
                }
            }).catch(err => {
                reject(err)
            })
        })
    },
    getCarrier() {
        return new Promise((resolve, reject) => {
            const data = {
                pageIndex: 1,
                pageRows: 10
            }
            getCarrier(data).then(response => {
                const { Code, Data, Msg } = response
                if (Code === 200) {
                    resolve(Data)
                } else {
                    reject(Msg)
                }
            }).catch(err => {
                reject(err)
            })
        })
    },
    getTimer() {
        return new Promise((resolve, reject) => {
            const data = {
                pageIndex: 1,
                pageRows: 10
            }
            getTimer(data).then(response => {
                const { Code, Data, Msg } = response
                if (Code === 200) {
                    resolve(Data)
                } else {
                    reject(Msg)
                }
            }).catch(err => {
                reject(err)
            })
        })
    },
    getCoupons({ rootState }, Params={}) {
        return new Promise((resolve, reject) => {
            const data = {
                pageIndex: 1,
                pageRows: 10,
                clientId: rootState.user.userInfo.Id,
                IsUse: Params.IsUse,
                IsEffective: Params.IsEffective
            }
            getCoupon(data).then(response => {
                const { Code, Data, Msg } = response
                if (Code === 200) {
                    resolve(Data)
                } else {
                    reject(Msg)
                }
            }).catch(err => {
                reject(err)
            })
        })
    },
    // eslint-disable-next-line no-empty-pattern
    getAddedService({}, type) {
        return new Promise((resolve, reject) => {
            const data = {
                pageIndex: 1,
                pageRows: 10,
                Type: type
            }
            getAdded(data).then(response => {
                const { Code, Data, Msg } = response
                if (Code === 200) {
                    resolve(Data)
                } else {
                    reject(Msg)
                }
            }).catch(err => {
                reject(err)
            })
        })
    },
    getCountry({ state }) {
        return new Promise((resolve, reject) => {
            const data = {
                tenantId: state.TenantId
            }
            CountryInfo(data).then(response => {
                const { Code, Data, Msg } = response
                if (Code === 200) {
                    resolve(Data)
                } else {
                    reject(Msg)
                }
            }).catch(err => {
                reject(err)
            })
        })
    },
    async getPayTypes({ state, commit }) {
        const data = {
            PageIndex: 1,
            PageRows: 10,
            ShopId: state.shopID
        }
        return new Promise((resolve, reject) => {
            getPayTypes(data).then(response => {
                const { Code, Msg, Data } = response
                if (Code === 200) {
                    commit('SET_PPY_TYPE', Data)
                    resolve(Data)
                } else {
                    reject(Msg)
                }
            }).catch(err => {
                reject(err)
            })
        })
    },
    // eslint-disable-next-line no-unused-vars
    async tuneUpPay({ state }, params={}) {
        const data = {
            OutTradeNo: params.OutTradeNo,
            Description: params.Description,
            Money: params.Money,
            PayTypeId: params.PayTypeId,
        }
        return new Promise((resolve, reject) => {
            getPayTypes(data).then(response => {
                const { Code, Msg, Data } = response
                if (Code === 200) {
                    resolve(Data)
                } else {
                    reject(Msg)
                }
            }).catch(err => {
                reject(err)
            })
        })
    },
    async carouselInit({ state }, type) {
        const data = {
            PageIndex: 1,
            PageRows: 10,
            ShopId: state.shopID,
            Type: type,
            TenantId: state.TenantId
        }
        return new Promise((resolve, reject) => {
            getShopImage(data).then(response => {
                const { Code, Msg, Data } = response
                if (Code === 200) {
                    resolve(Data)
                } else {
                    reject(Msg)
                }
            }).catch(err => {
                reject(err)
            })
        })
    },
    async initialConfiguration({ commit }) {
        return new Promise((resolve, reject) => {
            getConfig().then(response => {
                const { Code, Data } = response
                if (Code === 200) {
                    commit('SET_SYSTEM_CONFIG', Data)
                }
                resolve()
            }).catch(err => {
                reject(err)
            })
        })
    },
    appInit({ commit }) {
        const ShopUrl = process.env.NODE_ENV === 'development' ? "www.zhanbeikj.com" : window.location.hostname;
        return new Promise((resolve, reject) => {
            GetShopInfo({ shopURL: ShopUrl }).then(response => {
                const {Code, Data, Msg} = response
                if (Code === 200) {
                    const {
                        Id,
                        TenantId,
                        H5URL,
                        ThreeWayLink,
                        PcLogoUrl,
                        IsXP,
                        Name,
                        PcIcoUrl,
                        LanguageCode,
                        TaxMode,
                        RecordNo
                    } = Data
                    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
                    const isMobile = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
                    const route = router.currentRoute;
                    if (isMobile) {
                        if (route.name === 'Register') {
                            let hash = window.location.hash
                            hash = hash.replace('register', 'pages/login/register')
                            window.open(`${window.location.protocol}//${H5URL}/${hash}`, "_self")
                        } else if (route.name === 'Trajectory') {
                            window.open(`${window.location.protocol}//${H5URL}/#/pages/order/search`, "_self")
                        } else {
                            window.open(`${window.location.protocol}//${H5URL}`, "_self")
                        }
                    }
                    document.getElementById('dynamic-favicon').href = PcIcoUrl
                    commit('SET_TAX_MODE', TaxMode)
                    commit('SET_DEFAULT_LANGUAGE', LanguageCode)
                    commit('SET_RIGHT_BOX', JSON.parse(ThreeWayLink))
                    commit('SET_SHOPEE', IsXP)
                    commit('SET_RECORD_NO', RecordNo)
                    commit('SET_SHOP_ID', Id)
                    commit('SET_SHOP_NAME', Name)
                    commit('SET_TENANT_ID', TenantId)
                    commit('SET_LOGO_URL', PcLogoUrl)
                } else {
                    reject(Msg)
                }
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    }
}


export default {
    namespaced: true,
    state,
    mutations,
    actions
}
